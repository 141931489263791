import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Layout, Drawer, Button } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";

import Auth from "./auth";
import Logo from "../images/logo.png";
import LogoPrice2Click from "../images/logo_price2click.png";
import HeaderDownloadAndroidApp from "./HeaderDownloadAndroidApp";
import CountrySelection from "./CountrySelection";
import * as actions from "../redux/actions";

function HeaderMain(props) {
  const { loggedUserData } = props;
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    props.isLoggedInUser();
  }, []);

  let yesLoggedUser =
    (loggedUserData &&
      loggedUserData.token &&
      loggedUserData.email &&
      loggedUserData.name) ||
    false;

  let menuHeaderTitle = "Menu";

  if (yesLoggedUser) {
    menuHeaderTitle = (
      <>
        <UserOutlined style={{ color: "#1f1c4f", fontSize: 16 }} />{" "}
        <b>{loggedUserData?.name || ""}</b>
      </>
    );
  }

  return (
    <>
      <div
        style={{
          background: "yellow",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        *Cheapshops.in is now
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          {" "}
          price2click.com
        </span>
      </div>
      <HeaderDownloadAndroidApp />
      <div id="main-sticky-header" style={{}}>
        {/* <div className="bg-header"></div> */}
        <Row className="fix-header-row">
          <Col xl={22} md={22} xs={22} className="wrapper-logo">
            <Row>
              <Col xl={2} md={2} xs={2}>
                <CountrySelection />
              </Col>
              <Col>
                <Link to="/">
                  {window.DOMAIN_NAME === "price2click" ? (
                    <img
                      className="logo"
                      src={LogoPrice2Click}
                      style={{ width: 150, marginTop: "0px" }}
                    />
                  ) : (
                    <img className="logo" src={Logo} style={{ width: 170 }} />
                  )}

                  {/* <span className="header-logo-text">Cheapshops.in</span> */}
                </Link>
              </Col>
            </Row>
          </Col>

          <Col
            className="auth-block"
            xl={2}
            md={2}
            xs={2}
            // style={{ textAlign: "right", paddingRight: "20px" }}
          >
            {yesLoggedUser ? (
              <UserOutlined
                onClick={showDrawer}
                style={{ color: "black", fontSize: 24 }}
              />
            ) : (
              <MenuOutlined
                onClick={showDrawer}
                style={{ color: "black", fontSize: 24 }}
              />
            )}

            <Drawer
              title={menuHeaderTitle}
              placement="right"
              onClose={onClose}
              open={open}
              width={300}
            >
              <Auth closeDrawer={onClose} />
            </Drawer>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loggedUserData: state.auth.login.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderMain)
);
