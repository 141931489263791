const saveUserWebsitesToProcess = (arr) => {
  localStorage.setItem("WEBSITES_TO_PROCESS", JSON.stringify(arr));
};

const saveUserSearchCategoty = (data) => {
  localStorage.setItem("SEARCH_CATEGORY", JSON.stringify(data));
};

const getUserWebsitesToProcess = (arr) => {
  let tmp = localStorage.getItem("WEBSITES_TO_PROCESS");
  let tmpArr = [];
  try {
    tmpArr = JSON.parse(tmp);
  } catch (e) {
    tmpArr = [];
  }
  return tmpArr;
};

const sortProductsList = (products, sortOrder) => {
  let existingSortOrder = sortOrder.find((o) => o.selected === true);
  existingSortOrder = existingSortOrder.key;
  if (existingSortOrder === "priceHighToLow") {
    products.sort((a, b) => b.price - a.price);
  } else {
    products.sort((a, b) => a.price - b.price);
  }
  return products;
};

const setCountryCode = (data) => {
  localStorage.setItem("COUNTRY_CODE", data);
};

const getCountryCode = () => {
  return localStorage.getItem("COUNTRY_CODE") || "IN";
};

const getWebsiteLogo = (website) => {
  let url =
    "https://price2click.com/api/static/images/websites_logo/" +
    website +
    ".png";
  return url;
};

export {
  saveUserWebsitesToProcess,
  getUserWebsitesToProcess,
  sortProductsList,
  saveUserSearchCategoty,
  setCountryCode,
  getCountryCode,
  getWebsiteLogo,
};
