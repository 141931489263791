import constants from "./constants";
import { takeLatest, all } from "redux-saga/effects";

import { getWebsites, getMenu } from "./generic/action";
import * as actions from "./actions";

import {
  getCatalogProducts,
  getTopPriceDropProducts,
  getTopPopularProducts,
  getTopRecentProducts,
} from "./products/action";

import { productWebSearchRequest } from "./websearch/action";

import {
  getProductRequest,
  toggleFavouriteProductRequest,
  buyNowClickRequest,
  /* new action with generator */
  getProductLatestPrice,
} from "./product/action";

import {
  registerRequest,
  loginRequest,
  isLoggedInUser,
  logoutRequest,
  updatePasswordRequest,
  resetPasswordRequest,
  verifyEmailRequest,
  sendEmailVerifyCodeRequest,
  getMyFullDataRequest,
} from "./auth/action";

import { adminGetCompleteStats, adminGetUsersList } from "./admin/action";

function* watchActions() {
  /**
   * start - new actions
   */

  yield takeLatest(actions.getCatalogProducts.REQUEST, getCatalogProducts);

  yield takeLatest(actions.getWebsites.REQUEST, getWebsites);
  yield takeLatest(actions.getMenu.REQUEST, getMenu);
  yield takeLatest(
    actions.getProductLatestPrice.REQUEST,
    getProductLatestPrice
  );

  yield takeLatest(
    actions.getTopPriceDropProducts.REQUEST,
    getTopPriceDropProducts
  );
  yield takeLatest(
    actions.getTopPopularProducts.REQUEST,
    getTopPopularProducts
  );
  yield takeLatest(actions.getTopRecentProducts.REQUEST, getTopRecentProducts);

  //admin
  yield takeLatest(
    actions.adminGetCompleteStats.REQUEST,
    adminGetCompleteStats
  );
  yield takeLatest(actions.adminGetUsersList.REQUEST, adminGetUsersList);
  /**
   * end - new actions
   */

  yield takeLatest(
    constants.PRODUCT_WEB_SEARCH_REQUEST,
    productWebSearchRequest
  );

  yield takeLatest(constants.BUY_NOW_CLICK_REQUEST, buyNowClickRequest);

  yield takeLatest(constants.GET_PRODUCT_REQUEST, getProductRequest);

  yield takeLatest(
    constants.TOGGLE_FAVOURITE_PRODUCT_REQUEST,
    toggleFavouriteProductRequest
  );

  /*get user full data */
  yield takeLatest(constants.GET_MY_FULL_DATA_REQUEST, getMyFullDataRequest);

  /*register*/
  yield takeLatest(constants.REGISTER_REQUEST, registerRequest);

  /*login*/
  yield takeLatest(constants.LOGIN_REQUEST, loginRequest);

  /* check if user is logged in*/
  yield takeLatest(constants.IS_LOGGEDIN_USER, isLoggedInUser);

  /*log out*/
  yield takeLatest(constants.LOGOUT_REQUEST, logoutRequest);

  /* update password */
  yield takeLatest(constants.UPDATE_PASSWORD_REQUEST, updatePasswordRequest);

  /*send email verification code*/
  yield takeLatest(
    constants.SEND_EMAIL_VERIFY_CODE_REQUEST,
    sendEmailVerifyCodeRequest
  );

  /*verify email */
  yield takeLatest(constants.VERIFY_EMAIL_REQUEST, verifyEmailRequest);

  /*reset password*/
  yield takeLatest(constants.RESET_PASSWORD_REQUEST, resetPasswordRequest);
}

export default function* rootSaga() {
  yield all([watchActions()]);
}
