import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { Row, Col } from "antd";
// import SideDrawer from "../SideDrawer";
// import FormEditParking from "../forms/FormEditParking";
import * as actions from "../../redux/actions";
// import MyStats from "./MyStats";

// import { get_tempVariableToHold_isEmailVerified } from "../../services";
// import Search from "antd/lib/transfer/search";

import SearchBox from "./SearchBox";
import SearchBoxNew from "./SearchBoxNew";
import WebsitesAvailable from "./WebsitesAvailable";
import Menu from "../Menu";
import GoogleAdsenseResponsive from "../GoogleAdsenseResponsive";
import AllStores from "../AllStores";
import ProductWebSearch from "../ProductWebSearch";
import ProductsListVertical from "../ProductsListVertical";

function Landing(props) {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     // openNewParkingForm: false,
  //     // openEditParkingForm: false,
  //     // currentEditParkingData: false,
  //   };
  // }

  // componentDidMount() {
  // if (this.props.loggedUserData) {
  //   this.props.myParkingsRequest();
  //   // this.props.myStatsRequest();
  // }
  // }

  const _renderLandingInformation = () => {
    return (
      <Row id="landing-information">
        <Col className="" xl={24} md={24} xs={24}>
          <h1 className="landing-heading-1" style={{ color: "#1f1c4f" }}>
            Find Online Product Price
          </h1>
          <div className="landing-heading-2" style={{ color: "#1f1c4f" }}>
            Search product prices from various e-commerce websites
          </div>
          {/* <div className="landing-heading-3">
            List your parking space for Rent, Exchange, Sale
          </div> */}
        </Col>
      </Row>
    );
  };

  // let { myLinks } = this.props;
  // let { loggedUserData, myParkings, websitesToProcess } = this.props;
  let { websitesToProcess } = props;

  // let myShortShoutUrl =
  //   process.env.REACT_APP_WEB_BASE_URL + "/" + loggedUserData.username;
  // let linksToShow = myLinks.filter((link) => link.status === "active");

  // let isEmailVerified =
  //   (loggedUserData && loggedUserData.isEmailVerified) || false;
  // if (isEmailVerified === false) {
  //   isEmailVerified = get_tempVariableToHold_isEmailVerified();
  // }

  return (
    <>
      <div className="landing-first-fit-view">
        {/* <div className="bg"></div> */}
        {_renderLandingInformation()}

        <SearchBoxNew />
        <WebsitesAvailable />

        <Row>
          <div
            className="search-text-hint"
            style={{ margin: "0 auto", textAlign: "center" }}
          >
            <Link
              style={{ color: "#1f1c4f", textDecoration: "underline" }}
              to="/popular-products"
            >
              Popular
            </Link>
            &nbsp; &nbsp; &nbsp;
            <Link
              style={{ color: "#1f1c4f", textDecoration: "underline" }}
              to="/recent-products"
            >
              Recent
            </Link>
            &nbsp; &nbsp; &nbsp;
            <Link
              style={{ color: "#1f1c4f", textDecoration: "underline" }}
              to="/price-drop-products"
            >
              Price Drops
            </Link>
            &nbsp; &nbsp; &nbsp;
            <Link
              style={{ color: "#1f1c4f", textDecoration: "underline" }}
              to="/products"
            >
              Catalog
            </Link>
            &nbsp; &nbsp; &nbsp;
            <Link
              style={{ color: "#1f1c4f", textDecoration: "underline" }}
              to="/stores"
            >
              Stores
            </Link>
            &nbsp; &nbsp; &nbsp;
            <Link
              style={{ color: "#1f1c4f", textDecoration: "underline" }}
              to="/all-categories"
            >
              All Categories
            </Link>
          </div>
        </Row>

        <Row>
          <Col md={24} xl={24} sm={24} xs={24}>
            <GoogleAdsenseResponsive />
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col md={8} xl={8} sm={24} xs={24}>
            {props?.topPriceDropProducts &&
            props?.topPriceDropProducts.length > 0 ? (
              <div style={{ marginTop: 30, paddingLeft: 10, paddingRight: 10 }}>
                <ProductsListVertical
                  heading="Price Drops"
                  products={props?.topPriceDropProducts}
                  // pagePath="ScreenPriceDropProducts"
                  onViewAll={() => {
                    props.history.push({
                      pathname: "/price-drop-products",
                      // search: searchParams.toString(),
                    });
                  }}
                />
              </div>
            ) : null}
          </Col>
          <Col md={8} xl={8} sm={24} xs={24}>
            {props?.topRecentProducts && props?.topRecentProducts.length > 0 ? (
              <div style={{ marginTop: 30, paddingLeft: 10, paddingRight: 10 }}>
                <ProductsListVertical
                  heading="Recent Products"
                  products={props?.topRecentProducts}
                  // pagePath="ScreenPriceDropProducts"
                  onViewAll={() => {
                    // console.log("Add link...");
                    // linkTo({
                    //   screen: "ScreenProducts",
                    //   params: { catalogType: "priceDropProducts" },
                    // });
                    props.history.push({
                      pathname: "/recent-products",
                      // search: searchParams.toString(),
                    });
                  }}
                />
              </div>
            ) : null}
          </Col>

          <Col md={8} xl={8} sm={24} xs={24}>
            {props?.topPopularProducts &&
            props?.topPopularProducts.length > 0 ? (
              <div style={{ marginTop: 30, paddingLeft: 10, paddingRight: 10 }}>
                <ProductsListVertical
                  heading="Popular Products"
                  products={props?.topPopularProducts}
                  // pagePath="ScreenPriceDropProducts"
                  onViewAll={() => {
                    props.history.push({
                      pathname: "/popular-products",
                      // search: searchParams.toString(),
                    });
                  }}
                />
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={24} xl={24} sm={24} xs={24}>
            <GoogleAdsenseResponsive />
          </Col>
        </Row>
        <div className="menu-landing">
          <Menu />
        </div>

        <div className="menu-landing">
          <AllStores />
        </div>

        <Row>
          <br />
          <br />
          <Col md={24} xl={24} sm={24} xs={24}>
            <GoogleAdsenseResponsive />
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  // myParkings: state.parkings.myParkings.data || [],
  // newLink: state.links.newLink || false,
  // loggedUserData: state.auth.login.data || false,
  // links: state.links,
  // myLinks: state.links.myLinks.data || [],
  // myStats: state.dashboard.myStats,
  // updateLink: state.links.updateLink || false,
  websitesToProcess: state.websearch.productWebSearch.websitesToProcess || [],
  topPriceDropProducts: state?.products?.topPriceDropProducts?.data || [],
  topPopularProducts: state.products.topPopularProducts.data || [],
  topRecentProducts: state.products.topRecentProducts.data || [],
});

const mapDispatchToProps = (dispatch) => ({
  // myParkingsRequest: (data) => dispatch(actions.myParkingsRequest(data)),
  // deleteParkingRequest: (data) => dispatch(actions.deleteParkingRequest(data)),
  // updateParkingStatusRequest: (data) =>
  //   dispatch(actions.updateParkingStatusRequest(data)),
  // newLinkRequest: (data) => dispatch(actions.newLinkRequest(data)),
  // updateLinkRequest: (data) => dispatch(actions.updateLinkRequest(data)),
  // myLinksRequest: (data) => dispatch(actions.myLinksRequest(data)),
  // deleteLinkRequest: (data) => dispatch(actions.deleteLinkRequest(data)),
  // updateLinkStatusRequest: (data) =>
  //   dispatch(actions.updateLinkStatusRequest(data)),
  // myStatsRequest: (data) => dispatch(actions.myStatsRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Landing)
);
