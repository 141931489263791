import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import { Row, Col, Table } from "antd";
import * as actions from "../../redux/actions";

function StatsCountryWise(props) {
  const { admin } = props;
  const countryWiseStats = admin?.stats?.data?.countryWiseStats || [];
  const _getTableColumns = () => {
    const columns = [
      {
        title: "Flag",
        dataIndex: "flagCode",
        key: "flagCode",
        render: (data) => {
          return (
            <ReactCountryFlag style={{ fontSize: 30 }} countryCode={data} svg />
          );
        },
      },
      {
        title: "Country",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "Currency",
        dataIndex: "currency",
        key: "currency",
      },
      {
        title: "Total Products",
        dataIndex: "totalProducts",
        key: "totalProducts",
      },
      {
        title: "Latest Product Created At",
        dataIndex: "lastProductCreatedAt",
        key: "lastProductCreatedAt",
        render: (data) => {
          if (data) {
            return moment(data).format("DD-MMM-YYYY, h:mm:ss a");
          }
          return "--";
        },
      },
      {
        title: "Latest Product Updated At",
        dataIndex: "lastProductUpdatedAt",
        key: "lastProductUpdatedAt",
        render: (data) => {
          if (data) {
            return moment(data).format("DD-MMM-YYYY, h:mm:ss a");
          }
          return "--";
        },
      },
    ];
    return columns;
  };
  return (
    <>
      <Row id="my-parkings">
        <Col xl={24} md={24} xs={24}>
          <h3>Country Wise Stats</h3>
          <Table
            columns={_getTableColumns()}
            dataSource={countryWiseStats}
            size="small"
            pagination={{ pageSize: 100 }}
          />
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  adminGetUsersRequest: (data) => dispatch(actions.adminGetUsersRequest(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StatsCountryWise)
);
