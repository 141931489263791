import React from "react";
import { Row, Col } from "antd";

// import { Text, View, ScrollView, Pressable } from "react-native";

import TemplateProductRowView from "../components/product/TemplateProductRowView/TemplateProductRowView";

function ProductsListVertical(props) {
  const { heading, products } = props;
  return (
    <>
      <Col
        style={{
          background: "#1890ff",
          color: "white",
        }}
      >
        <Row>
          <Col
            xs={12}
            md={12}
            xl={12}
            style={{
              // textAlign: "center",
              // color: "black",
              fontWeight: "bold",
              width: "50%",
              fontSize: 16,
              marginBottom: 2,
              paddingLeft: 10,
            }}
          >
            {heading}
          </Col>
          <Col
            xs={12}
            md={12}
            xl={12}
            style={{
              textAlign: "right",
              paddingRight: 10,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={props?.onViewAll}
          >
            View all
          </Col>
        </Row>
      </Col>
      <div
        style={{
          // backgroundColor: "pink",
          borderRadius: 10,
          marginBottom: 20,
          // overflow: "scroll",
        }}
      >
        <div
          style={{
            marginBottom: 20,
            // height: 400
          }}
          // showsVerticalScrollIndicator={false}
          // showsHorizontalScrollIndicator={false}
          // contentContainerStyle={{ flexGrow: 1 }}
          // nestedScrollEnabled={true}
        >
          <div
            style={{
              flexDirection: "column",
            }}
          >
            {products.map((product, index) => {
              return (
                <TemplateProductRowView
                  data={product}
                  key={index}
                  showViewCount={true}
                  hasPriceHistory={true}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsListVertical;
