import { combineReducers } from "redux";

import generic from "./generic/reducer";
import auth from "./auth/reducer";
import admin from "./admin/reducer";
import websearch from "./websearch/reducer";
import product from "./product/reducer";
import products from "./products/reducer";

const appReducer = combineReducers({
  generic,
  auth,
  admin,
  websearch,
  product,
  products,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
