import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./styles/index.scss";
import LandingPage from "./containers/LandingPage";
import RouteChangeTracker from "./RouteChangeTracker";

import AuthRegisterPage from "./containers/AuthRegisterPage";
import AuthLoginPage from "./containers/AuthLoginPage";

import hideForAuthUser from "./components/auth/hideForAuthUser";

import MyDashboard from "./containers/MyDashboard";
import MasterAdmin from "./containers/MasterAdmin";
import requireUserAuth from "./components/auth/requireUserAuth";
import requireMasterAdminAuth from "./components/auth/requireMasterAdminAuth";

import PageWebSearch from "./containers/PageWebSearch";
import PageProduct from "./containers/PageProduct";
import Disclaimer from "./containers/Disclaimer";
import PageProducts from "./containers/PageProducts";
import PageAllStores from "./containers/PageAllStores";
import PageAllCategoires from "./containers/PageAllCategoires";

import PrivacyPolicy from "./containers/PrivacyPolicy";

import ProgressBarWrapper from "./components/ProgressBarWrapper";

import * as actions from "./redux/actions";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

var RAW_HOSTNAME = window.location.host;
// var HOSTNAME = "cheapshops"; // for R&D only
var HOSTNAME = "price2click"; // from 16th jan 2025 - this is the final domain, cheapshops now redirect to price2click
if (RAW_HOSTNAME.indexOf("price2click.com") !== -1) {
  HOSTNAME = "price2click";
}
console.log("HOSTNAME -- " + HOSTNAME);

window.DOMAIN_NAME = HOSTNAME;

function App(props) {
  const { menu } = props;

  useEffect(() => {
    props.getMenuRequest();
    props.getWebsitesRequest();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/register"
          component={hideForAuthUser(AuthRegisterPage)}
        />
        <Route exact path="/login" component={hideForAuthUser(AuthLoginPage)} />
        <Route
          // exact
          path="/price-search/:searchText"
          component={PageWebSearch}
        />

        <Route
          // exact
          path="/stores"
          component={PageAllStores}
        />

        <Route
          // exact
          path="/all-categories"
          component={PageAllCategoires}
        />

        <Route
          // exact
          path="/product/:website/:productId/:name"
          component={PageProduct}
        />

        <Route exact path="/products" component={PageProducts} />
        <Route exact path="/price-drop-products" component={PageProducts} />
        <Route exact path="/popular-products" component={PageProducts} />
        <Route exact path="/recent-products" component={PageProducts} />
        <Route path="/store/:website" component={PageProducts} />

        <Route
          // exact
          path="/products/:categoryId/:categoryName"
          component={PageProducts}
        />

        <Route
          path="/(mydashboard|mydashboard-editProfile|mydashboard-updatePassword)/"
          component={requireUserAuth(MyDashboard)}
        />

        <Route
          path="/(admin|admin-manageUsers)/"
          component={requireMasterAdminAuth(MasterAdmin)}
        />

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
      <RouteChangeTracker />
      <ProgressBarWrapper />
    </Router>
  );
}

function NoMatch() {
  window.location = "/";
  return null;
}

//export default App;

const mapStateToProps = (state) => ({
  menu: state.generic.menu.data || false,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedInUser: (data) => dispatch(actions.isLoggedInUser(data)),
  getWebsitesRequest: (data) => dispatch(actions.getWebsites.request()),
  getMenuRequest: (data) => dispatch(actions.getMenu.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
