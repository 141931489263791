// import ReactGA from "react-ga";
import ReactGA from "react-ga4";

let configID = "G-2ZZZ3R31GJ";
if (window.location.host.indexOf("price2click.com") !== -1) {
  configID = "G-5Y23X7MZL6";
}

if (process.env.NODE_ENV === "development") {
} else {
  ReactGA.initialize(configID);
}

// gtag('config', 'G-5Y23X7MZL6');

const trackPage = () => {
  if (process.env.NODE_ENV === "development") {
  } else {
    const { location } = window;
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }
};

const trackEvent = (data) => {
  if (process.env.NODE_ENV === "development") {
  } else {
    const { category, action, label, value } = data;
    ReactGA.event({
      category: category || "",
      action: action || "",
      label: label.toString() || "",
      value: label * 1 || 0,
    });
  }
};

export { trackPage, trackEvent };
