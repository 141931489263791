import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Row, Col, Layout, Button } from "antd";
import HeaderMain from "../components/HeaderMain";
import FooterMain from "../components/FooterMain";

import Admin from "../components/admin";
import ManageUsers from "../components/admin/users";

const { Header, Content, Footer } = Layout;

class MasterAdmin extends React.Component {
  render() {
    return (
      <Layout id="masteradmin-page">
        <HeaderMain />
        <Content className="page-content">
          <div id="menu-list">
            <Link to="/admin">
              <Button type="link">Admin Dashboard</Button>
            </Link>

            <Link to="/admin-manageUsers">
              <Button type="link">Manage Users</Button>
            </Link>
          </div>

          <div className="sub-pages">
            <Route>
              <Route exact path="/admin" component={Admin} />
              <Route path="/admin-manageUsers" component={ManageUsers} />
            </Route>
          </div>
        </Content>
        <FooterMain />
      </Layout>
    );
  }
}

export default MasterAdmin;
