import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { fireApi, showNotification } from "../../services";

export function* adminGetCompleteStats(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getCompleteStats`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetCompleteStats.success(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetCompleteStats.failure(e.response.data));
  }
}

export function* adminGetUsersList(action) {
  const header = {};
  try {
    const response = yield call(
      fireApi,
      "POST",
      `/admin/getUsersList`,
      action.payload,
      header
    );
    if (response) {
      yield put(actions.adminGetUsersList.success(response.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.adminGetUsersList.failure(e.response.data));
  }
}
