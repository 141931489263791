import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row } from "antd";

import TemplateProductSmallView from "../product/TemplateProductSmallView";

import * as actions from "../../redux/actions";

function MyFavouriteProducts(props) {
  const { favouriteProducts } = props;

  useEffect(() => {
    props.getMyFullDataRequest();
  }, []);

  return (
    <div id="search-page">
      <h1 style={{ color: "#1f1c4f" }}>My Favourites</h1>
      <Row gutter={16}>
        {favouriteProducts.map((product, index) => {
          return (
            <TemplateProductSmallView data={product.productInfo} key={index} />
          );
        })}
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  favouriteProducts: state.auth.myFullData.data.favouriteProducts || [],
});

const mapDispatchToProps = (dispatch) => ({
  getMyFullDataRequest: (data) => dispatch(actions.getMyFullDataRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyFavouriteProducts);
